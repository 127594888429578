import styled from "styled-components"

export const ContactPageStyles = styled.div`
  img {
    position: relative;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    opacity: 0.4;

    @media (max-width: 1100px) {
      height: 800px;
    }
  }
  #contact-container {
    position: absolute;
    top: 15rem;
    display: grid;
    left: 50%;
    margin-left: -426px;
    grid-template-columns: 50% 50%;

    @media all and (-ms-high-contrast: none) {
      display: -ms-flexbox;
    }

    @media (max-width: 1100px) {
      grid-template-columns: 100%;
      margin-left: -150px;
      top: 10rem;
    }
    #company-info {
      justify-self: right;
      margin-left: 100px;
      ul {
        /* position: absolute; */
        list-style-type: none;
      }
      @media (max-width: 1100px) {
        justify-self: center;
        margin: 0;
      }
    }

    #contact-form {
      margin-left: 175px;
      width: 300px;
      .input-bottom-boxes {
        width: 200px;
      }
      input {
        margin: 10px 0;
        padding: 5px;
        width: 100%;
      }

      textarea {
        width: 100%;
        height: 100px;
        margin: 10px 0;
        padding: 5px;
      }
      button {
        width: 100%;
        margin: 10px 0;
        padding: 10px;
        background-color: #333;
        color: white;
        border: none;
        text-align: center;
      }
      @media (max-width: 1100px) {
        justify-self: center;
        margin: 0;
      }
    }
  }
`
