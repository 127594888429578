import React from "react"
import MainLayout from "../components/MainLayout"
import PageHeading from "../components/PageHeadings"
import { ContactPageStyles } from "../components/styles/ContactPageStyles"
import ServiceImage from "../images/nico-customer-service-1.jpg"
import ContactForm from "../components/ContactForm"

const ContactPage = () => {
  return (
    <MainLayout>
      <PageHeading
        pageHeading="Contact Us"
        // description="Let us help you cray direct trade  readymade narwhal YOLO cornhole Kickstarter kitsch wayfarers artisan synth Vice seitan"
      />
      <ContactPageStyles>
        <img src={ServiceImage} alt="" />
        <div id="contact-container">
          <div id="company-info">
            <h4>Address:</h4>
            <ul>
              <li>RPS Corporation</li>
              <li>1711 South Street</li>
              <li>Racine, WI 53404 USA</li>
              <li>Toll free: (877) 401-0730</li>
            </ul>
            <h4>Business Hours:</h4>
            Monday - Friday 8:00am - 5:00pm (CT)
          </div>

          <div id="contact-form">
            <h4>Contact Us</h4>
            <ContactForm />
          </div>
        </div>
      </ContactPageStyles>
    </MainLayout>
  )
}

export default ContactPage
